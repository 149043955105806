import styled from "styled-components"

export default styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: 1em;

  @media all and (max-width: 699px) { 
    display: none;
  }

`