import React from "react"
import Masthead from "./masthead"
import Footer from "./footer"
import '../styles/layout.css'

const Layout = ({children, catNodes, title, email, footer, instagramLink, facebookLink, twitter, linkedInLink, ccImage, ccLink}) => (
  <>
    <Masthead title={title} catNodes={catNodes} />
    {children}
    <Footer title={title} email={email} footer={footer} instagramLink={instagramLink} facebookLink={facebookLink} twitter={twitter} linkedInLink={linkedInLink} ccImage={ccImage} ccLink={ccLink} />
  </>
)

export default Layout