import React from "react"
import {Link} from 'gatsby'
import {getResourceCategoryUrl} from '../lib/helpers'

import Search from "./search"
const searchIndices = [{ name: `Pages`, title: `Pages` }]

function Nav (props) {

  const {catNodes} = props

  return (
    <>
    <ul className="nav-menu">
      <li><Link title="About" to="/about/">About</Link></li>
      <li>
        <Link title="Blog" to="/blog/">Blog</Link>
      </li>
      <li>
        <Link title="Portfolio" className="down" to="/making/">Making</Link>
        <div className="drop">
        <ul class="dropdown" aria-label="submenu">
          <li><Link title="Books" href="/books/">Books</Link></li>
          <li><Link title="Fonts" to="/fonts/">Fonts</Link></li>
          <li><Link title="Presentations" href="/presentations/">Presentations</Link></li>
          <li><Link title="Projects" href="/projects/">Projects</Link></li>
          <li><Link title="Student work" href="/student-work/">Student work</Link></li>
        </ul>
        </div>
      </li>
      <li><Link title="Quotes" to="/quotes/">Quotes</Link></li>
      <li>
        <Link className="down" to="/resources/">Resources</Link>
        <div className="drop">
        <ul class="dropdown" aria-label="submenu">
          {catNodes && catNodes.map(cat => (
              <li><Link title={cat.title} to={getResourceCategoryUrl(cat.slug.current)}>{cat.title}</Link></li>
            ))}
        </ul>
        </div>
      </li>
    </ul>
    <Search indices={searchIndices} />
    </>
  )
}

export default Nav








