import {format, isFuture} from 'date-fns'

export function cn (...args) {
  return args.filter(Boolean).join(' ')
}

export function mapEdgesToNodes (data) {
  if (!data.edges) return []
  return data.edges.map(edge => edge.node)
}

export function filterOutDocsWithoutSlugs ({ slug }) {
  return (slug || {}).current
}

export function filterOutCatsWithoutParents ({ parents }) {
  if (parents === undefined || parents.length === 0) {
    return parents;
  }
  return false;
}

export function filterOutCatsWithParents ({ parents }) {
  if (parents === undefined || parents.length === 0) {
    return false;
  }
  return parents;
}

export function filterOutDocsWithoutYears ({ year }) {
  if (year === undefined) {
    return false;
  }
  return year;
}

export function filterOutDocsPublishedInTheFuture ({publishedAt}) {
  return !isFuture(publishedAt)
}

export function buildImageObj (source) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id }
  }
  if (source.crop) imageObj.crop = source.crop
  if (source.hotspot) imageObj.hotspot = source.hotspot
  return imageObj
}

export function buildRawImageObj (source) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id }
  }
  return imageObj
}

export function getBlogUrl (publishedAt, slug) {
  return `/blog/${format(publishedAt, 'YYYY/MM')}/${slug.current || slug}/`
}

export function getPresentationUrl (publishedAt, slug) {
  return `/presentations/${format(publishedAt, 'YYYY/MM')}/${slug.current || slug}/`
}

export function getBlogCategoryUrl (slug) {
  return `/blog/${slug.current || slug}/`
}

export function getResourceCategoryUrl (slug) {
  return `/resources/${slug.current || slug}/`
}

export function getProjectUrl (publishedAt, slug) {
  return `/projects/${format(publishedAt, 'YYYY/MM')}/${slug.current || slug}/`
}

export function getBookUrl (publishedAt, slug) {
  return `/books/${format(publishedAt, 'YYYY/MM')}/${slug.current || slug}/`
}

export function getStudentWorkUrl (year, slug) {
  return `/student-work/${year}/${slug.current || slug}/`
}

export function getFontUrl (slug) {
  return `/fonts/${slug.current || slug}/`
}

export function getQuoteUrl (catalogNumber) {
  return `/quote/${catalogNumber}/`
}

export function showCurrentYear() {
    return new Date().getFullYear();
}

export function leadingZeros (number) {

  if(number < 100 && number >= 10) {
    number = '0'+number 
  }else if(number < 10) {
    number = '00'+number 
  }

  return number
}

export function toPlainText (blocks) {
  if (!blocks) {
    return ''
  }
  return blocks
    .map(block => {
      if (block._type !== 'block' || !block.children) {
        return ''
      }
      return block.children.map(child => child.text).join('')
    })
    .join('\n\n')
}

export function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}

export function prettify(str) {
    return str.split('-').map(function capitalize(part) {
        return part.charAt(0).toUpperCase() + part.slice(1);
    }).join(' ');
}