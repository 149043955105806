import styled, { css } from "styled-components"
import SearchBox from "./search-box"

const open = css`
  width: 12em;
  background: ${({ theme }) => theme.background};
  cursor: text;
  margin-left: -1.7em;
  padding-left: 1.8em;
  border-bottom: 1px solid ${({ theme }) => theme.foreground};
`

const closed = css`
  width: 0;
  background: transparent;
  cursor: pointer;
  margin-left: -1.7em;
  padding-left: 1em;
  border-bottom: 1px solid ${({ theme }) => theme.background};
`

export default styled(SearchBox)`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 0;

  .SearchInput {
    outline: none;
    border: none;
    padding: .5em;
    font-size: .85em;
    transition: 200ms;
    color: ${({ theme }) => theme.foreground};
    ::placeholder {
      color: ${({ theme }) => theme.faded};
    }
    ${({ hasFocus }) => (hasFocus ? open : closed)}
  }

  .SearchIcon {
    width: 1em;
    margin: 0.3em;
    color: ${({ theme }) => theme.foreground};
    pointer-events: none;
  }
`