import React from "react"
import Nav from './nav'
import {Link} from 'gatsby'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import Logo from "../images/svg/logo.svg";
import "../styles/masthead.css"

class Masthead extends React.Component {
  constructor(props) {
    super(props);
      this.toggleNav = this.toggleNav.bind(this);
      this.state = {
        active: false,
      };
  }

  escFunction = (e) => {
    // check keys if you want
    //if (e.keyCode === 27) {
      //this.setState({
        //active: !this.state.active,
      //});
    //}
  }

  toggleNav() {
    this.setState({
      active: !this.state.active,
    });

    if(this.state.active) {
      enableBodyScroll();
    } else {
      disableBodyScroll();
    }

  }

  componentDidMount(){
    document.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount(){
    clearAllBodyScrollLocks();
    document.removeEventListener("keydown", this.escFunction, false);
  }

  render() {
    const classNavOverlay = '' + (this.state.active ? 'active' : '')

    //console.log('props')
    //console.log(this.props)

    return (

      <>
        <div id="masthead-holder">
        <div className="site-wrapper">
          <div id="masthead" className="grid">
            
            <div id="logo" className="width-9-12-m width-4-12">
              <Link title="Alessandro Segalini" to="/">
                <Logo />
                <h1>{this.props.title}</h1>
              </Link>
            </div>

            <div id="nav-button" className="width-3-12-m width-12-12">
              <div id="nav-button-holder">
                <button id="hamburger" onClick={this.toggleNav} className="hamburger vertically-centered" type="button">
                  <span className="hamburger-box">
                    <span className="hamburger-inner" />
                  </span>
                </button>
              </div>
            </div>

            <div id="nav" className={classNavOverlay}>
                <button id="hamburger-close" tabIndex={0} onClick={this.toggleNav} onKeyDown={(e) => this.toggleKeyNav(e)} className="hamburger vertically-centered x" type="button">
                  <span className="hamburger-box">
                    <span className="hamburger-inner" />
                  </span>
                </button>
                <Nav catNodes={this.props.catNodes} />
            </div>
          </div>
        </div>
        </div>

      </>

    )
  }
}

export default Masthead
