import React from 'react'
import {Link} from 'gatsby'
import Logobox from "../images/svg/logobox.svg";
import "../styles/footer.css"
import {showCurrentYear} from '../lib/helpers'
import {FaInstagram} from 'react-icons/fa';
import {FaTwitter} from 'react-icons/fa';
import {FaLinkedin} from 'react-icons/fa';
import {FaFacebook} from 'react-icons/fa';
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

const Footer = ({title, email, footer, instagramLink, facebookLink, twitter, linkedInLink, ccImage, ccLink}) => (

  <div id="footer">
  <div className="grid wrapper">
    <div className="width-3-12-m width-6-12">
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/about/">About</Link></li>
        <li><Link to="/blog/">Blog</Link></li>
        <li><Link to="/making/">Making</Link></li>
        <li><Link to="/fonts/">Fonts</Link></li>
        <li><Link to="/quotes/">Quotes</Link></li>
        <li><Link to="/resouces/">Resources</Link></li>
        <li><Link to="/colophon/">Colophon</Link></li>
      </ul>
    </div>
    <div className="width-9-12-m width-6-12">

      <div className="footer-info">
        <div className="footer-logo">
          <Logobox />
        </div>
        <div className="footer-text">
          <p>©{showCurrentYear()} {title}<br />
            {email}
          </p>
          <p>
            {footer}
          </p>
          <p>
            <a aria-label="Creative Commons" target="_blank" href={ccLink} rel="noopener noreferrer">
            <img
            src={imageUrlFor(buildImageObj(ccImage))
              .width(120)
              .url()}
            alt={ccImage.alt}
            title={ccImage.alt}
          /></a>
          </p>
          <div className="footer-social">
            <a aria-label="Instagram" rel="noopener noreferrer" title="Instagram" href={instagramLink} target="_blank"><FaInstagram /></a>
            <a aria-label="Twitter" rel="noopener noreferrer" title="Twitter" href={twitter} target="_blank"><FaTwitter /></a>
            <a aria-label="Facebook" rel="noopener noreferrer" title="Facebook" href={facebookLink} target="_blank"><FaFacebook /></a>
            <a aria-label="LinkedIn" rel="noopener noreferrer" title=" LinkedIn" href={linkedInLink} target="_blank"><FaLinkedin /></a>
      </div>
        </div>
      </div>
    </div>
  </div>
</div>
)

export default Footer