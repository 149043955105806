import { Link } from "gatsby"
import { default as React } from "react"
import {
  connectStateResults,
  Highlight,
  InfiniteHits,
  Index,
  Snippet,
} from "react-instantsearch-dom"
import {
  getBlogUrl,
  getProjectUrl,
  getBookUrl,
  getFontUrl,
  getQuoteUrl,
  getPresentationUrl

} from '../../lib/helpers'

function getUrl(path, slug, publishedAt){

  if(path === 'blog'){
    return getBlogUrl(publishedAt, slug)
  }else if(path === 'projects'){
    return getProjectUrl(publishedAt, slug)
  }else if(path === 'presentations'){
    return getPresentationUrl(publishedAt, slug)
  }else if(path === 'books'){
    return getBookUrl(publishedAt, slug)
  }else if(path === 'fonts'){
    return getFontUrl(slug)
  }else if(path === 'quote'){
    return getQuoteUrl(slug)
  }

}

const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits

  return hitCount > 0 ? (
    <div className="HitCount">
      {hitCount} result{hitCount !== 1 ? `s` : ``}
    </div>
  ) : null
})

const PostHit = ({ hit }) => (
  <div>
     <Link to={getUrl(hit.path, hit.slug, hit.publishedAt)}>
      <h4>
        <Highlight attribute="title" hit={hit} tagName="mark" />
      </h4>
    </Link>
    <Snippet attribute="body" hit={hit} tagName="mark" />
  </div>
)

const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <HitCount />
    <InfiniteHits className="Hits" hitComponent={PostHit} />
  </Index>

)

const SearchResult = ({ indices, className }) => (
  <div className={className}>
    {indices.map(index => (
      <HitsInIndex index={index} key={index.name} />
    ))}
  </div>
)

export default SearchResult