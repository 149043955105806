import styled, { css } from "styled-components"
import SearchResult from "./search-result"

const Popover = css`
  max-height: 80vh;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  z-index: 2;
  right: 0;
  top: 100%;
  margin-top: 1em;
  width: 100vw;
  max-width: 25em;
  box-shadow: 0 4px 8px rgba(0,0,0,.08);
  padding: 1em;
  background: ${({ theme }) => theme.background};
`

export default styled(SearchResult)`
  display: ${props => (props.show ? `block` : `none`)};
  ${Popover}

  .HitCount {
    font-size: .85em;
    display: flex;
    justify-content: flex-end;
  }

  .Hits {
    ul {
      list-style: none;
      margin-left: 0;
      padding-left: 0;
    }

    li .ais-Snippet {
      display: inline-block;
      width: 100%;
      font-size: .85em;
    }

    .ais-Highlight-highlighted, .ais-Snippet-highlighted  {
      background: transparent;
      border-bottom: 1px solid ${({ theme }) => theme.foreground};
    }

    li.ais-InfiniteHits-item {
      margin-bottom: 2em;

      a {
        text-decoration: none;

        h4 {
          transition: background-color .2s ease-in-out, color .2s ease-in-out;
          display: inline-block;
          float: left;
          padding: .05em .25em; 
          color: ${({ theme }) => theme.foreground};
          background: ${({ theme }) => theme.background};
          margin-bottom: 0.25em;
        }

        .ais-Highlight-highlighted, .ais-Snippet-highlighted  {
          transition: color .2s ease-in-out, border-bottom .2s ease-in-out;
          border-bottom: 1px solid ${({ theme }) => theme.foreground};
        }

      }

      a:hover {
        text-decoration: none;

        h4 {
          color: ${({ theme }) => theme.background};
          background: ${({ theme }) => theme.foreground};
          padding: .05em .25em; 
          margin-bottom: 0.25em;
        }

        .ais-Highlight-highlighted, .ais-Snippet-highlighted  {
          color: ${({ theme }) => theme.background};
          border-bottom: 1px solid ${({ theme }) => theme.background};
        }

      }

    }

    .ais-InfiniteHits-loadMore {
      border-radius: 0px;
      margin-bottom: 1em;
      border: none;
      cursor: pointer;
      padding: .25rem calc(.5rem - 1px);
    }

    .ais-InfiniteHits-loadMore--disabled {
      opacity: 0;
      visibility: hidden;
      display: none;
    }

  }

`